import { compose } from '@ngrx/store';
import { endOfDay, startOfDay } from 'date-fns';
import mapValues from 'lodash-es/mapValues';
import { createSelector } from 'reselect';

import { parseDate } from '../../../shared/date.helper';
import { AppState } from '../../index';
import { mapEntity } from '../../shared/entity.helper';
import { HolidayModel, HolidayState } from './holiday.model';

export const getHolidayState = (appState: AppState): HolidayState => appState.orm.holidays;

export const getHolidayIds = compose((state) => state.items, getHolidayState);

export const getHolidayEntities = createSelector(getHolidayState, (state) =>
  mapValues(state.itemsById, (holiday: HolidayModel) => {
    const startDateTime = startOfDay(parseDate(holiday.date));
    const endDateTime = endOfDay(parseDate(holiday.date));

    return {
      ...holiday,
      startDateTime,
      endDateTime,
    };
  }),
);

export const getHolidays = createSelector(getHolidayState, (state: HolidayState) => Object.values(state.itemsById));

export const getHoliday = (id: string) => createSelector(getHolidayEntities, (entities) => mapEntity(id, entities));
